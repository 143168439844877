<template>
  <div class="report">
    <div class="errors" v-if="errors">
      <h2>Ошибки при сохранении</h2>
      <table>
        <thead>
          <tr>
            <th>Student</th>
            <th>Group</th>
            <th>Value</th>
            <th>Date</th>
            <th>Text</th>
            <th>Source</th>
            <th>Error</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(error, index) in errors" :key="index">
            <td>
              <router-link
                :to="{
                  name: 'Student',
                  params: { student_id: error.object.student.id },
                }"
              >
                {{ error.object.student.name }}
              </router-link>
            </td>
            <td v-if="error.object.order.type == 'lesson'">Tutor</td>
            <td v-else>{{ error.object.group.name }}</td>
            <td>{{ error.object.value }} р.</td>
            <td>
              {{ $moment(error.object.pay_day * 1000).format("DD.MM.YYYY") }}
            </td>
            <td>{{ error.object.text }}</td>
            <td>
              {{ error.object.source ? error.object.source.source_name : "-" }}
            </td>
            <td>{{ error.message }}</td>
          </tr>
          <tr>
            <td colspan="2">Total</td>
            <td colspan="5">{{ totalError }} р.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="success" v-if="savedItems">
      <h2>Сохраненные данные</h2>
      <table>
        <thead>
          <tr>
            <th>Student</th>
            <th>Group</th>
            <th>Value</th>
            <th>Date</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in savedItems" :key="index">
            <td>
              <router-link
                :to="{
                  name: 'Student',
                  params: { student_id: item.student.id },
                }"
                target="_blank"
              >
                {{ item.student.name }}
              </router-link>
            </td>
            <td v-if="item.order.type == 'lesson'">Tutor</td>
            <td v-else>{{ item.group.name }}</td>
            <td>{{ item.value }} р.</td>
            <td>{{ $moment(item.pay_day * 1000).format("DD.MM.YYYY") }}</td>
            <td>{{ item.source ? item.source.source_name : "-" }}</td>
          </tr>
          <tr>
            <td colspan="2">Total</td>
            <td colspan="4">{{ totalSaved }} р.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentSavedReport",
  props: ["savedItems", "errors"],
  computed: {
    totalSaved: function () {
      return this.savedItems.reduce((sum, item) => {
        if (item.value) sum = sum + parseFloat(item.value);
        return sum;
      }, 0);
    },
    totalError: function () {
      return this.errors.reduce((sum, item) => {
        if (item.object.value) sum = sum + parseFloat(item.object.value);
        return sum;
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  margin: 10px 0;
  td,
  th {
    padding: 5px 10px;
    border: 1px solid #ccc;
  }
}
</style>