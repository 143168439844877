<template>
  <div class="payments__form-item">
    <div
      class="student__line"
      :style="{ color: group ? group.color : null }"
    ></div>
    <div class="student__link element">
      <router-link
        :to="{ name: 'Student', params: { student_id: student.id } }"
        target="_blank"
      >
        <span :class="{ crossed: !student.is_active }">{{ student.name }}</span>
      </router-link>
    </div>
    <div class="order element">
      <span
        v-if="orderValue"
        class="discount"
        :class="{
          d15: orderDisount == 15,
          d25: orderDisount == 25,
          d50: orderDisount == 50,
        }"
        :title="orderDisount ? `${orderDisount} %` : ``"
        >{{ orderValue }} р.</span
      >
    </div>
    <div class="date element">
      <input @keyup.enter="submit" v-model="date" placeholder="payment date" />
    </div>
    <div class="value element">
      <input @keyup.enter="submit" v-model="value" placeholder="value" />
    </div>
    <div class="source element">
      <input
        @keyup.enter="submit"
        v-model="source"
        placeholder="payment source"
        :list="sourceDatalistId"
      />
    </div>
    <div class="submit element">
      <button @click="submit">OK</button>
    </div>
    <div class="balance order element">
      <span
        class="credit border"
        :class="{
          negative: balance < 0,
          d15: orderDisount == 15,
          d25: orderDisount == 25,
          d50: orderDisount == 50,
        }"
        >{{ balance }} р.</span
      >
    </div>
    <div class="balance student element">
      <div
        class="credit"
        :class="{
          negative: diff_fact < 0,
        }"
      >
        <div class="balance__label">Balance fact:</div>
        {{ diff_fact.toFixed(2) }} р.
      </div>
    </div>
    <div class="balance student element">
      <div
        class="credit"
        :class="{
          negative: diff_plan < 0,
        }"
      >
        <div class="balance__label">Balance plan:</div>
        {{ diff_plan.toFixed(2) }} р.
      </div>
    </div>
    <div class="send element" v-if="order">
      <order-send-button
        v-if="student && student.order"
        :order="order"
        @setOrderAsSended="setOrderAsSended"
      />
    </div>
    <div class="balance student element">
      <div
        class="credit"
        :class="{
          negative: book_balance_fact < 0,
        }"
        v-if="book_balance_fact != 0"
      >
        <div class="balance__label">Book balance fact:</div>
        {{ book_balance_fact.toFixed(2) }} р.
      </div>
    </div>
  </div>
</template>

<script>
import OrderSendButton from '../../orders/orderSendButton.vue'
export default {
  props: ['student', 'group', 'type'],
  data() {
    const order = this.student ? this.student.order : null
    return {
      date: '',
      value: '',
      source: '',
      orderValue: order.id ? order.value : null,
      orderDisount: order.id ? order.discount : 0,
      payments: order ? order.payments : 0,
      balance_fact: this.student.balance.diff_fact,
      balance_plan: this.student.balance.diff_plan,
      book_balance_fact: this.student.book_balance?.diff_fact || 0,
      sourceDatalistId: 'source_list',
      order: this.student.order,
    }
  },
  components: { OrderSendButton },
  computed: {
    balance: function() {
      if (this.orderValue)
        return this.$round(
          this.payments +
            parseFloat(this.value || 0) -
            parseFloat(this.orderValue || 0)
        )
      return this.$round(this.value)
    },
    diff_fact: function() {
      return this.balance_fact + parseFloat(this.value || 0)
    },
    diff_plan: function() {
      return this.balance_plan + parseFloat(this.value || 0)
    },
    schoolyear_id: function() {
      return this.order.schoolyear_id
        ? this.order.schoolyear_id
        : this.group.schoolyear_id
        ? this.group.schoolyear_id
        : null
    },
    books_diff_fact: function() {
      return this.o
    },
  },
  methods: {
    submit: function() {
      let { date, value, source } = this
      const pay_day = this.$moment(date, 'DD.MM.YYYY')
      const data = {
        student_id: this.student.id,
        value,
        source_synonym: source,
        pay_day: pay_day.unix(),
        schoolyear_id: this.schoolyear_id,
      }
      if (this.type == 'tutor') {
        data.order_id = this.order.id
        data.order_type = 'OrdersStudentsLesson'
      } else {
        data.group_id = this.group.id
      }

      this.$store
        .dispatch('payments/setOrderPaymentAction', { payment: data })
        .then(data => {
          // TODO: clear form
          this.$vs.notify({
            title: `Payment saved`,
            text: `Payment with value ${data.saved[0].value}р. saved`,
            color: 'success',
            position: 'top-right',
          })
          this.date = ''
          this.value = ''
          this.source = ''
          this.payments += data.saved[0].value
          this.balance_fact += data.saved[0].value
          this.balance_plan += data.saved[0].value
        })
        .catch(string => {
          this.$vs.notify({
            title: `error in save payment`,
            text: `${string}`,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    setOrderAsSended: function(order) {
      const { id, sended, value, payments } = order
      this.order = {
        id,
        sended,
        value,
        payments,
      }
    },
  },
}
</script>

<style lang="scss" scope>
.payments__form-item {
  background-color: #f1f1f1;
  margin: 3px 0;
  box-shadow: 0 0 2px 1px #ccc;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  input {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 7px;
    width: 100%;
  }
  .student__line {
    width: 4px;
    background-color: currentColor;
    position: absolute;
    height: 100%;
  }
  .order {
    width: 120px;
  }
  .element {
    padding: 7px;
    &.value,
    &.date,
    &.source {
      width: 10%;
    }
    &.send {
      width: 6rem;
    }
  }
  .credit {
    &.border {
      padding: 3px 7px;
      border: 2px solid transparent;
      border-radius: 5px;
      width: 100%;
      display: block;
      text-align: center;
    }
    &.d15 {
      border-color: var(--font-discount-15);
    }
    &.d25 {
      border-color: var(--font-discount-25);
    }
    &.d50 {
      border-color: var(--font-discount-50);
    }
  }
  .student__link {
    padding: 10px 25px;
    width: 20%;
    // min-width: 250px;
  }
  .submit {
    button {
      background-color: rgba(var(--vs-primary), 1);
      color: #fff;
      padding: 7px 15px;
      border-radius: 7px;
      border: none;
      &:hover {
        box-shadow: 0 0 3px 2px #ccc;
      }
    }
  }
  .balance {
    &.student {
      text-align: right;
      .balance__label {
        font-size: 0.7em;
        vertical-align: middle;
      }
    }
  }
}
</style>
