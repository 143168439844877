<template>
  <div class="group-books">
    <div class="group-books__header">
      <router-link :to="{ name: 'Group', params: { group_id: group.id } }">
        <vs-avatar icon="people" :color="group.color"></vs-avatar>
        <span>Group: {{ group.name }}</span>
      </router-link>
      <vs-chip class="group-books__lessons">
        <span
          v-for="(lessonTime, index) in group.lessons_time"
          :key="index"
          class="group-books__lessons__time"
        >
          {{ lessonTime.name }} - {{ lessonTime.time }}
        </span>
      </vs-chip>
      <vs-button
        type="border"
        @click="giveOutHandler"
        icon="call_missed_outgoing"
        size="small"
        color="success"
        v-if="toGiveOut.length"
        class="group-books__give-out__button"
        >Give out selected</vs-button
      >
      <vs-button type="flat" @click="selectAllHandler"
        >Select all books to give out</vs-button
      >
    </div>

    <div v-for="s in group.students" :key="s.id" class="group-books__students">
      <router-link
        :to="{ name: 'Student', params: { student_id: s.id } }"
        target="_blank"
      >
        <span>{{ s.name }}</span>
      </router-link>
      <div class="orders" v-for="o in s.orders" :key="o.id">
        <book-form-item v-model="toGiveOut" :order="o" :student="s" />
      </div>
    </div>
  </div>
</template>

<script>
import bookFormItem from "./bookFormItem.vue";
import giveOutBookByLinks from "@/api/books/giveOutBookByLinks";

export default {
  name: "groupBooksOrders",
  components: { bookFormItem },
  props: ["group"],
  data() {
    return {
      toGiveOut: [],
    };
  },
  methods: {
    giveOutHandler: function () {
      this.$vs.dialog({
        type: "confirm",
        color: "success",
        title: "Give out book",
        text: "Are you sure you want to give out selected books?",
        accept: () => {
          giveOutBookByLinks
            .call(this, {
              group_id: this.group.id,
              book_link_ids: this.toGiveOut,
            })
            .then((data) => {
              this.$vs.notify({
                title: "Success",
                text: `Book set to give out in lesson ${data.data.lesson.name}`,
                color: "success",
                position: "top-right",
              });
              this.$emit("updateBooks");
              setTimeout(() => (this.toGiveOut = []), 500);
            })
            .catch((e) => this.showError(e));
        },
      });
    },
    selectAllHandler: function () {
      let booksLinks = [];
      for (var s of this.group.students) {
        for (var o of s.orders) {
          for (var b of o.books) {
            if (!b.free && !b.give_out) booksLinks.push(b.link_id);
          }
        }
      }
      this.toGiveOut = booksLinks;
    },
  },
};
</script>

<style lang="scss">
.group-books {
  &__header {
    display: flex;
    gap: 10px;
    flex-flow: row nowrap;
    align-items: center;
  }
  &__give-out__button {
    i {
      font-size: var(--small-icon-size);
    }
  }
  &__lessons {
    display: flex;
    flex-flow: row nowrap;
    &__time {
      margin: 0 0.5rem;
    }
  }
}
</style>