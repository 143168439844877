<template>
  <div>
    <div class="stat__filter">
      <label for="">Date Range</label>
      <v-date-picker v-model="range" mode="date" is-range ref="calendar">
        <template v-slot="{ inputValue, inputEvents }">
          <div>
            <vs-input
              class="calendar-input"
              :value="`${inputValue.start} - ${inputValue.end}`"
              v-on="inputEvents.start"
            />
          </div>
        </template>
      </v-date-picker>
      <span
        class="quick-filter quick-date-filter"
        @click="setRange($event, 'today')"
        >Today</span
      >
      <span
        class="quick-filter quick-date-filter"
        @click="setRange($event, 'month')"
        >This month</span
      >
      <span
        class="quick-filter quick-date-filter"
        @click="setRange($event, 'prevMonth')"
        >Prev month</span
      >
      <!-- <span class="quick-date-filter" @click="setRange()">School Year</span> -->
    </div>
    <div class="stat__filter">
      <label for="">Groups</label>
      <v-select
        label="name"
        :options="groupsItems"
        v-model="filterGroups"
        :reduce="item => item.id"
        autocomplete="on"
        :closeOnSelect="false"
        multiple
        clearable
        placeholder="All"
      >
        <template #option="{ color, name }">
          <vs-avatar icon="people" :color="color"></vs-avatar>
          {{ name }}
        </template>
        <template #selected-option="{ color, name }">
          <vs-avatar icon="people" :color="color" size="20px"></vs-avatar>
          {{ name }}
        </template>
      </v-select>
    </div>
    <div class="stat__filter">
      <label for="">Books</label>
      <v-select
        label="name"
        :options="booksItems"
        v-model="filterBooks"
        :reduce="item => item.id"
        autocomplete="on"
        :closeOnSelect="false"
        multiple
        clearable
        placeholder="All"
      >
        <template #option="{ color, name }">
          <vs-avatar icon="people" :color="color"></vs-avatar>
          {{ name }}
        </template>
        <template #selected-option="{ color, name }">
          <vs-avatar icon="people" :color="color" size="20px"></vs-avatar>
          {{ name }}
        </template>
      </v-select>
    </div>
    <div class="stat__filter">
      <label for="">Students</label>
      <v-select
        label="name"
        :options="studentItemsFilter"
        v-model="filterStudents"
        :reduce="item => item.id"
        autocomplete="on"
        :closeOnSelect="false"
        clearable
        multiple
        placeholder="All"
      >
        <template #option="{ name }">
          <vs-avatar icon="person"></vs-avatar>
          {{ name }}
        </template>
        <template #selected-option="{ name }">
          <vs-avatar icon="person" size="20px"></vs-avatar>
          {{ name }}
        </template>
      </v-select>
    </div>
    <div class="stat__filter">
      <label for="">Payment Source</label>
      <v-select
        label="source_name"
        :options="sourcesItems"
        v-model="filterSources"
        :reduce="item => item.id"
        autocomplete="on"
        :closeOnSelect="false"
        clearable
        multiple
        placeholder="All"
      >
        <template #selected-option="{ source_name }">
          <vs-chip color="#f1efc8">
            {{ source_name }}
          </vs-chip>
        </template>
      </v-select>
    </div>
    <div class="stat__filter">
      <label for=""></label>
      <vs-button @click="filterSubmit">Filter</vs-button>
      <vs-button @click="clearFiltersHandler" color="dark" type="flat"
        >Clear</vs-button
      >
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import { mapState } from 'vuex'
export default {
  props: ['groups', 'books', 'students', 'dateFrom', 'dateTo', 'sources'],
  data() {
    return {
      filterGroups: this.groups,
      filterBooks: this.books,
      filterStudents: this.students,
      filterSources: this.sources,
      range: {
        start: this.dateTo || new Date(),
        end: this.dateFrom || new Date(),
      },
    }
  },
  components: {
    'v-select': vSelect,
  },
  computed: {
    ...mapState({
      groupsItems: state => state.groups.groups,
      booksItems: state => state.books.books,
      studentsItems: state => state.students.students,
      sourcesItems: state => state.payments.sources,
      curYear: state => state.years.currentSchoolYear,
    }),
    studentItemsFilter: function() {
      if (this.filterGroups.length) {
        return this.groupsItems.reduce((arr, group) => {
          if (this.filterGroups.includes(group.id)) {
            let students = group.students.filter(s => {
              return !arr.some(a => a.id == s.id)
            })
            arr = [...arr, ...students]
          }
          return arr
        }, [])
      }
      return this.studentsItems
    },
  },
  methods: {
    filterSubmit: function() {
      const filters = {
        groups: this.filterGroups,
        books: this.filterBooks,
        students: this.filterStudents,
        sources: this.filterSources,
        dateFrom: this.range.end,
        dateTo: this.range.start,
      }
      this.$emit('setFilters', filters)
    },
    setRange: async function(e, range) {
      e.preventDefault()
      const calendar = this.$refs.calendar
      let dateTo = new Date()
      switch (range) {
        case 'today':
          this.range = {
            start: dateTo,
            end: dateTo,
          }
          break
        case 'month': {
          let start = new Date()
          start.setDate(1)
          this.range = {
            start,
            end: dateTo,
          }
          break
        }
        case 'prevMonth': {
          let dateFrom = this.$moment()
            .subtract(1, 'months')
            .startOf('month')
            .toDate()
          dateTo = this.$moment()
            .subtract(1, 'months')
            .endOf('month')
            .toDate()
          this.range = {
            start: dateFrom,
            end: dateTo,
          }

          break
        }
      }
      try {
        await calendar.move({
          month: dateTo.getMonth() + 1,
          year: dateTo.getFullYear(),
        })
      } catch (e) {
        console.log(e)
      }
      this.filterSubmit()
    },
    clearFiltersHandler: function() {
      this.filterGroups = []
      this.filterBooks = []
      this.filterStudents = []
      this.filterSources = []
      let dateFrom = new Date()
      dateFrom.setDate(1)
      let dateTo = new Date()
      this.range = {
        start: dateFrom,
        end: dateTo,
      }
      this.filterSubmit()
    },
  },
  created: async function() {
    const data = {
      schoolyear_id: this.curYear.id,
    }
    await this.$store.dispatch('groups/getGroupsAction', { data })
    await this.$store.dispatch('students/getStudentsAction')
    await this.$store.dispatch('payments/getSourcesAction')
    await this.$store.dispatch('books/getBooksAction')
  },
  watch: {
    groups: function(newV) {
      console.log('watch', newV)
      this.filterGroups = newV
    },
    sources: function(newV) {
      console.log('watch', newV)
      this.filterSources = newV
    },
    books: function(newV) {
      this.filterBooks = newV
    },
    students: function(newV) {
      this.filterStudents = newV
    },
  },
}
</script>

<style lang="scss">
.vs__actions {
  .vs__clear {
    display: block !important;
  }
}
.stat__filters {
  label {
    font-size: 0.9em;
    margin: 5px 0;
    display: block;
    color: var(--thead-text-color);
    min-height: 1rem;
  }
  & > div {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
  }
  .stat__filter {
    flex: 1;
  }
  .calendar-input {
    width: 100%;
    input {
      padding: 8px;
      font-size: 0.8rem;
    }
  }
}
</style>
