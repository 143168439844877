<template>
  <div class="items" v-if="payments.length">
    <div class="manage">
      <vs-button
        icon="refresh"
        type="flat"
        color="dark"
        @mouseup="refreshDataHandler"
        >Refresh</vs-button
      >
      <vs-button icon="save" @click="saveDataHandler" :disabled="loading"
        >Save all</vs-button
      >
      <div>Count: {{ payments.length }}</div>
      <vs-checkbox v-model="showGroupErrors">
        Empty group ({{ groupErrorsCount }})
      </vs-checkbox>
      <vs-checkbox v-model="showStudentErrors">
        Empty student ({{ studentErrorsCount }})
      </vs-checkbox>
      <vs-checkbox v-model="showOrderdErrors">
        Empty order ({{ orderErrorCount }})
      </vs-checkbox>
      <vs-button
        icon="list"
        color="success"
        v-if="savedPayments.length || errorsOnSavePayments.length"
        @mouseup="popupActive = true"
        >Show report</vs-button
      >
    </div>
    <div class="saving-progress" v-if="loading">
      <div class="saving-stat">
        saved: {{ savedPayments.length + errorsOnSavePayments.length }} of
        {{ payments.length }}
        <strong style="color: red">Do not close the page</strong>
      </div>
      <vs-progress :percent="savingPercent" color="primary"
        >primary</vs-progress
      >
    </div>

    <div class="item" v-for="item in filteredPayments" :key="item.index">
      <item
        :item="item"
        @deleteItem="deleteHandler"
        @updateItem="updateHandler"
      />
    </div>
    <vs-popup
      class="form-popup payment__popup"
      classContent="result__popup-content"
      :title="''"
      :active.sync="popupActive"
      v-if="savedPayments.length || errorsOnSavePayments.length"
    >
      <saved-report
        :savedItems="savedPayments"
        :errors="errorsOnSavePayments"
      />
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import parsePayments from "@/api/payments/parsePayments";
import Item from "./item.vue";
import SavedReport from "./savedReport.vue";

export default {
  name: "commonLoadFormWrapper",
  props: ["file_id"],
  data() {
    return {
      search: "",
      message: undefined,
      payments: [],
      showGroupErrors: false,
      showStudentErrors: false,
      showOrderdErrors: false,
      loading: false,
      savedPayments: [],
      errorsOnSavePayments: [],
      popupActive: false,
    };
  },
  components: { Item, SavedReport },
  computed: {
    ...mapState({
      currentSchoolYear: (state) => state.years.currentSchoolYear,
    }),
    filteredPayments: function () {
      let payments = JSON.parse(JSON.stringify(this.payments));
      if (this.showGroupErrors) {
        return payments.filter((p) => !p.group_id);
      }
      if (this.showStudentErrors) {
        return payments.filter((p) => !p.student_id);
      }
      if (this.showOrderdErrors) {
        return payments.filter((p) => !p.order);
      }
      return payments;
    },
    groupErrorsCount: function () {
      let errors = this.payments.filter((p) => !p.group_id);
      return errors ? errors.length : 0;
    },
    studentErrorsCount: function () {
      let errors = this.payments.filter((p) => !p.student_id);
      return errors ? errors.length : 0;
    },
    orderErrorCount: function () {
      return this.payments.filter((p) => !p.order).length;
    },
    savingPercent: function () {
      return (
        ((this.savedPayments.length + this.errorsOnSavePayments.length) * 100) /
        this.payments.length
      );
    },
  },
  methods: {
    parseData: function () {
      const { file_id } = this;
      const { id } = this.currentSchoolYear;
      if (!file_id) {
        return;
      }
      this.savedPayments = [];
      this.errorsOnSavePayments = [];
      this.$emit("setMessage", "Parse file...");
      const data = {
        file_id,
        schoolyear_id: id,
      };
      parsePayments
        .call(this, { data })
        .then((data) => {
          this.payments = data.data.map((d) => {
            const group_id = d.group ? d.group.id : undefined;
            const student_id = d.student ? d.student.id : undefined;
            const source_id = d.source ? d.source.id : undefined;
            return { ...d, group_id, student_id, source_id };
          });
          this.$emit("setMessage", "");
        })
        .catch((e) => {
          this.$emit("setMessage", `Error in parse data: ${e.message}`);
        });
    },
    deleteHandler: function (index) {
      const itemIndex = this.payments.findIndex((p) => p.index == index);
      this.payments.splice(itemIndex, 1);
    },
    updateHandler: function (data) {
      const itemIndex = this.payments.findIndex((p) => p.index == data.index);
      const item = this.payments.find((p) => p.index == data.index);
      Object.assign(item, data);
      this.payments.splice(itemIndex, 1, item);
    },
    refreshDataHandler: function () {
      this.$vs.dialog({
        color: "success",
        title: `Обновление данных`,
        text: "Уверены, что хотите обновить данные? все изменения будут потеряны.",
        accept: this.parseData,
      });
      return;
    },
    saveDataHandler: async function () {
      const { file_id } = this;
      let payments = this.payments.map((p) => {
        // Ужасный костыль, чтобы сохранялись оплаты по инд.занятиям
        // и не возникало ошибки незаполненной группы в форме!!
        const data = { ...p };

        const { id } = this.currentSchoolYear;
        if (data.group_id == "tutor") data.group_id = null;
        return { ...data, schoolyear_id: id, file_id };
      });

      if (this.groupErrorsCount || this.studentErrorsCount) {
        this.$vs.notify({
          title: `Errors`,
          text: `Есть незаполненные группы или ученики`,
          color: "danger",
          position: "top-right",
        });
        return;
      }

      this.loading = true;
      for (const payment of payments) {
        const response = await this.$store.dispatch(
          "payments/setOrderPaymentAction",
          { payment }
        );
        if (response.saved.length) this.savedPayments.push(...response.saved);
        else if (response.errors.length)
          this.errorsOnSavePayments.push(...response.errors);
      }
      this.loading = false;
      this.$vs.notify({
        title: "Payment saved",
        text: `All Payments saved. Click on Show report button to get saving report`,
        color: "success",
        position: "top-right",
        time: 6000,
      });
    },
  },
  watch: {
    file_id: function (newV) {
      if (newV) this.parseData();
    },
  },
};
</script>

<style lang='scss'>
.manage {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
  .vs-button {
    float: none;
  }
  align-items: center;
}
</style>