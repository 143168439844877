<template>
  <div class="book-payment-form">
    <div v-for="group in data" :key="group.id" class="payments__group-wrapper">
      <div :class="{ hidden: group.hidden }">
        <group-books-orders
          :group="group"
          @updateBooks="$emit('updateOrders')"
        />
      </div>
    </div>
    <datalist id="source_list">
      <option
        :value="source"
        v-for="(source, index) in customSources"
        :key="index"
      ></option>
    </datalist>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GroupBooksOrders from './groupBooksOrders.vue'
export default {
  components: { GroupBooksOrders },
  name: 'bookPaymtnForm',
  props: ['data', 'selectedDate'],
  computed: {
    ...mapState({
      sources: state => state.payments.sources,
    }),
    customSources: function() {
      return this.sources.map(s => s.source_synonyms.split(',')[0])
    },
  },
}
</script>

<style></style>
