<template>
  <div class="stat stat-wrapper">
    <div class="stat__filters">
      <stat-filters
        :groups="groups"
        :books="books"
        :students="students"
        :sources="sources"
        :dateFrom="dateFrom"
        :dateTo="dateTo"
        @setFilters="setFilterValues"
      />
    </div>
    <div class="stat__data" id="stat__data">
      <stat-totals
        :data="payments"
        :totals="totals"
        :showGroupsAndTutorsSum="showGroupsAndTutorsSum"
      />
      <stat-data
        :data="payments"
        :groups="groups"
        :books="books"
        :students="students"
        :dateFrom="dateFrom"
        :dateTo="dateTo"
        v-model="sort"
        @updateFilters="updateFiltersHandler"
      />
    </div>
  </div>
</template>

<script>
import getStat from '@/api/payments/getStat'
import statFilters from './statFilters.vue'
import StatData from './statData.vue'
import StatTotals from './statTotals.vue'
import getStatTotals from '@/api/payments/getStatTotals'

export default {
  components: { statFilters, StatData, StatTotals },
  data() {
    const dateFrom = new Date()
    const dateTo = new Date()
    dateTo.setDate(1)
    return {
      payments: [],
      groups: [],
      books: [],
      students: [],
      sources: [],
      totals: {},
      sort: '-created',
      dateFrom,
      dateTo,
    }
  },
  computed: {
    showGroupsAndTutorsSum: function() {
      return this.groups.length || this.students.length || this.books.length
        ? false
        : true
    },
  },
  methods: {
    getPayments: function() {
      this.$vs.loading({
        container: '#stat__data',
        scale: 0.6,
      })
      const { groups, books, students, dateFrom, dateTo, sources, sort } = this

      const query = {
        'filter.groups': groups,
        'filter.books': books,
        'filter.lessons': [],
        'filter.students': students,
        'filter.date_from': Math.floor(dateFrom.getTime() / 1000),
        'filter.date_to': Math.floor(dateTo.getTime() / 1000),
        'filter.sources': sources,
        sort,
      }
      getStatTotals.call(this, query).then(response => {
        this.totals = response.data
      })
      getStat.call(this, query).then(response => {
        this.payments = response.data
        this.$vs.loading.close('#stat__data > .con-vs-loading')
      })
    },
    setFilterValues: function(filters) {
      Object.assign(this, filters)
      this.getPayments()
    },
    updateFiltersHandler: function(filterKey, filterValue) {
      switch (filterKey) {
        case 'group':
          this.groups = filterValue
          break
        case 'book':
          this.books = filterValue
          break
        case 'source':
          this.sources = filterValue
          break
        case 'student':
          this.students = filterValue
          break
      }
      this.getPayments()
    },
  },
  mounted: function() {
    this.getPayments()
  },
  watch: {
    sort: function() {
      this.getPayments()
    },
  },
}
</script>

<style lang="scss"></style>
