<template>
  <div class="stat__totals">
    <span class="scroll-top">
      <scroll-to-top />
    </span>

    <span class="stat__totals__item">
      Q: <strong> {{ data.length }} </strong>
    </span>
    <span class="stat__totals__item">
      Recieved: <strong>{{ totalSum.toLocaleString() }} р. </strong>
    </span>
    <span class="stat__totals__item">
      Groups: <strong>{{ totalByType.groups.toLocaleString() }} р. </strong>
    </span>
    <span class="stat__totals__item">
      Tutoring: <strong>{{ totalByType.tutor.toLocaleString() }} р. </strong>
    </span>
    <span class="stat__totals__item">
      Books: <strong>{{ totalByType.books.toLocaleString() }} р. </strong>
    </span>
    <span class="stat__totals__item">
      Undefined:
      <strong>{{ totalByType.undefined.toLocaleString() }} р. </strong>
    </span>
    <span class="stat__totals__delimeter"></span>
    <div class="stat__totals__groups">
      <totals-data
        :totals="totals"
        :showGroupsAndTutorsSum="showGroupsAndTutorsSum"
      />
    </div>
  </div>
</template>

<script>
import scrollToTop from '../../common/scrollToTop.vue'
import TotalsData from './totalsData.vue'

export default {
  props: ['data', 'totals', 'showGroupsAndTutorsSum'],
  computed: {
    totalSum: function() {
      return this.data.reduce((sum, el) => sum + el.value, 0)
    },
    totalByType: function() {
      let output = {
        groups: 0,
        tutor: 0,
        books: 0,
        undefined: 0,
      }
      this.data.reduce((a, b) => {
        switch (b.type) {
          case 'groups':
            a.groups += b.value
            break
          case 'books':
            a.books += b.value
            break
          case 'lessons':
            a.tutor += b.value
            break
          default:
            a.undefined += b.value
            break
        }
        return a
      }, output)
      return output
    },
  },
  components: {
    TotalsData,
    scrollToTop,
  },
}
</script>

<style></style>
