<template>
  <div>
    <div class="payments__date__input">
      <v-date-picker v-model="date">
        <template v-slot="{ inputValue, inputEvents }">
          <vs-input
            :value="inputValue"
            class="inline-datepicker"
            readonly
            v-on="inputEvents"
            placeholder="Date"
            size="large"
          />
        </template>
      </v-date-picker>
    </div>
    <div class="payments__date__button">
      <vs-button size="small" icon="content_copy" @click="copyHandler"
        >Copy...</vs-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: new Date(),
    };
  },
  methods: {
    copyHandler: function () {
      let copyText = document.querySelector(".inline-datepicker input");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.$vs.notify({
        title: `${copyText.value} copied to clipboard`,
        text: ``,
        color: "success",
        position: "top-right",
      });
      setTimeout(() => {
        copyText.blur();
      }, 100);
    },
  },
  watch: {
    date: function (newV) {
      if (newV) {
        this.$emit("changeDate", newV);
      }
    },
  },
};
</script>

<style lang="scss">
.payments__date {
  box-shadow: 0 0 5px 0px #bbb;
  & > div {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
  }
  .vs-button {
    float: none;
    margin-left: 15px;
  }
}
</style>