<template>
  <div class="view-content">
    <Split>
      <SplitArea :size="75">
        <div class="year" v-if="selectedYearId">
          <vs-select v-model="selectedYearId" label="School Year">
            <vs-select-item
              :key="index"
              :value="item.id"
              :text="item.name"
              v-for="(item, index) in years"
            />
          </vs-select>
        </div>

        <div class="payments__bankform">
          <FileUploadForm
            type="payment"
            @startUpload="startLoadHandle"
            @successUpload="successUploadHandle"
          />
          <div class="loader">
            <div class="loader-message" v-if="message">{{ message }}</div>
          </div>
        </div>
        <items-list :file_id="file_id" @setMessage="setParseMessageHandle" />
      </SplitArea>
      <SplitArea :size="25">
        <div class="payments__file-list">
          <FilesList :files="files" :isEditable="true" />
        </div>
      </SplitArea>
    </Split>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FileUploadForm from '@/components/files/fileUploadForm'
import ItemsList from '@/components/payments/loadForm/itemsList'
import FilesList from '@/components/files/filesList'

export default {
  name: 'commonLoadFormWrapper',
  data() {
    return {
      search: '',
      message: undefined,
      payments: [],
      showGroupErrors: false,
      showStudentErrors: false,
      file_id: null,
      yearId: null,
      selectedYearId: null,
    }
  },
  components: { FileUploadForm, ItemsList, FilesList },
  computed: {
    ...mapState({
      years: state => state.years.years,
      currentSchoolYear: state => state.years.currentSchoolYear,
      files: state => state.files.files,
    }),
  },
  methods: {
    selectFileHandle: function(file_id) {
      this.file_id = file_id
    },
    startLoadHandle: function() {
      this.message = 'Upload...'
    },
    successUploadHandle: function(response) {
      this.file_id = response.id
    },
    setParseMessageHandle: function(message) {
      this.message = message
    },
  },
  watch: {
    selectedYearId: async function(newV) {
      const data = {
        schoolyear_id: newV,
      }
      await this.$store
        .dispatch('groups/getGroupsAction', { data })
        .then(() => {})
    },
  },
  created: async function() {
    await this.$store.dispatch('files/getFilesAction', { type: 'payment' })
    await this.$store.dispatch('students/getStudentsAction')
    this.selectedYearId = this.currentSchoolYear.id
    // setTimeout(() => {
    //   this.selectedYearId = this.currentSchoolYear.id
    // }, 200)
  },
}
</script>

<style lang="scss">
.manage {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
  .vs-button {
    float: none;
  }
  align-items: center;
}
.payments__file-list {
  padding: 0.5rem;
}
</style>
