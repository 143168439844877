<template>
  <div class="group_tabs">
    <vs-tabs>
      <vs-tab label="Manual">
        <slot name="manual"></slot>
      </vs-tab>
      <vs-tab label="Tutor Manual">
        <slot name="tutor-manual"></slot>
      </vs-tab>
      <vs-tab label="Books">
        <slot name="books"></slot>
      </vs-tab>
      <vs-tab label="From bank">
        <slot name="bank"></slot>
      </vs-tab>
      <vs-tab label="Statistic">
        <slot name="stat"></slot>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.con-vs-tabs {
  overflow: inherit;

  .con-slot-tabs {
    overflow: inherit;
  }
}
</style>
