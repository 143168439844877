<template>
  <div class="orders">
    <div class="status-bar">
      <h2>Payments</h2>
    </div>
    <div class="order-view" v-if="$can('manage', 'payments')">
      <PaymentTabs>
        <template v-slot:stat>
          <stat-wrapper />
        </template>
        <template v-slot:books>
          <book-wrapper />
        </template>
        <template v-slot:manual>
          <PaymentWrapper />
        </template>
        <template v-slot:bank>
          <LoadPaymentWrapper />
        </template>
        <template v-slot:tutor-manual>
          <PaymentWrapper type="tutor" />
        </template>
      </PaymentTabs>
    </div>
  </div>
</template>

<script>
import PaymentWrapper from '@/components/payments/commonForm/wrapper'
import LoadPaymentWrapper from '@/components/payments/loadForm/wrapper'
import PaymentTabs from '@/components/payments/paymentTabs'
import StatWrapper from '../components/payments/statistic/statWrapper.vue'
import BookWrapper from '../components/payments/books/bookWrapper.vue'
export default {
  title: 'SE-Payments',
  name: 'PaymentsCommonView',
  components: {
    PaymentWrapper,
    PaymentTabs,
    LoadPaymentWrapper,
    StatWrapper,
    BookWrapper,
  },
}
</script>
<style lang="scss">
.orders__list {
  margin: 0 15px;
}
</style>
