var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"stat__filter"},[_c('label',{attrs:{"for":""}},[_vm._v("Date Range")]),_c('v-date-picker',{ref:"calendar",attrs:{"mode":"date","is-range":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('div',[_c('vs-input',_vm._g({staticClass:"calendar-input",attrs:{"value":((inputValue.start) + " - " + (inputValue.end))}},inputEvents.start))],1)]}}]),model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}}),_c('span',{staticClass:"quick-filter quick-date-filter",on:{"click":function($event){return _vm.setRange($event, 'today')}}},[_vm._v("Today")]),_c('span',{staticClass:"quick-filter quick-date-filter",on:{"click":function($event){return _vm.setRange($event, 'month')}}},[_vm._v("This month")]),_c('span',{staticClass:"quick-filter quick-date-filter",on:{"click":function($event){return _vm.setRange($event, 'prevMonth')}}},[_vm._v("Prev month")])],1),_c('div',{staticClass:"stat__filter"},[_c('label',{attrs:{"for":""}},[_vm._v("Groups")]),_c('v-select',{attrs:{"label":"name","options":_vm.groupsItems,"reduce":function (item) { return item.id; },"autocomplete":"on","closeOnSelect":false,"multiple":"","clearable":"","placeholder":"All"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var color = ref.color;
var name = ref.name;
return [_c('vs-avatar',{attrs:{"icon":"people","color":color}}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
var color = ref.color;
var name = ref.name;
return [_c('vs-avatar',{attrs:{"icon":"people","color":color,"size":"20px"}}),_vm._v(" "+_vm._s(name)+" ")]}}]),model:{value:(_vm.filterGroups),callback:function ($$v) {_vm.filterGroups=$$v},expression:"filterGroups"}})],1),_c('div',{staticClass:"stat__filter"},[_c('label',{attrs:{"for":""}},[_vm._v("Books")]),_c('v-select',{attrs:{"label":"name","options":_vm.booksItems,"reduce":function (item) { return item.id; },"autocomplete":"on","closeOnSelect":false,"multiple":"","clearable":"","placeholder":"All"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var color = ref.color;
var name = ref.name;
return [_c('vs-avatar',{attrs:{"icon":"people","color":color}}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
var color = ref.color;
var name = ref.name;
return [_c('vs-avatar',{attrs:{"icon":"people","color":color,"size":"20px"}}),_vm._v(" "+_vm._s(name)+" ")]}}]),model:{value:(_vm.filterBooks),callback:function ($$v) {_vm.filterBooks=$$v},expression:"filterBooks"}})],1),_c('div',{staticClass:"stat__filter"},[_c('label',{attrs:{"for":""}},[_vm._v("Students")]),_c('v-select',{attrs:{"label":"name","options":_vm.studentItemsFilter,"reduce":function (item) { return item.id; },"autocomplete":"on","closeOnSelect":false,"clearable":"","multiple":"","placeholder":"All"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_c('vs-avatar',{attrs:{"icon":"person"}}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_c('vs-avatar',{attrs:{"icon":"person","size":"20px"}}),_vm._v(" "+_vm._s(name)+" ")]}}]),model:{value:(_vm.filterStudents),callback:function ($$v) {_vm.filterStudents=$$v},expression:"filterStudents"}})],1),_c('div',{staticClass:"stat__filter"},[_c('label',{attrs:{"for":""}},[_vm._v("Payment Source")]),_c('v-select',{attrs:{"label":"source_name","options":_vm.sourcesItems,"reduce":function (item) { return item.id; },"autocomplete":"on","closeOnSelect":false,"clearable":"","multiple":"","placeholder":"All"},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var source_name = ref.source_name;
return [_c('vs-chip',{attrs:{"color":"#f1efc8"}},[_vm._v(" "+_vm._s(source_name)+" ")])]}}]),model:{value:(_vm.filterSources),callback:function ($$v) {_vm.filterSources=$$v},expression:"filterSources"}})],1),_c('div',{staticClass:"stat__filter"},[_c('label',{attrs:{"for":""}}),_c('vs-button',{on:{"click":_vm.filterSubmit}},[_vm._v("Filter")]),_c('vs-button',{attrs:{"color":"dark","type":"flat"},on:{"click":_vm.clearFiltersHandler}},[_vm._v("Clear")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }