<template>
  <div>
    <vs-input
      placeholder="Search student..."
      v-model="search"
      @keyup="searchHandler"
      ref="input"
      size="large"
      description-text="Press F2 to focus on field"
    ></vs-input>
    <vs-icon class="search__clear" icon="close" @click="clearHandler"></vs-icon>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
    };
  },
  methods: {
    searchHandler: function () {
      this.$emit("searchEvent", this.search);
    },
    focusHandler: function (e) {
      if (e.key == "F2") {
        this.$refs.input.focusInput();
      }
    },
    clearHandler: function () {
      this.search = "";
      this.$emit("searchEvent", this.search);
    },
  },
  created: function () {
    window.addEventListener("keyup", this.focusHandler);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.focusHandler);
  },
};
</script>

<style lang="scss">
.payments__search {
  position: relative;
  .vs-input {
    width: 100%;
  }
  .search__clear {
    position: absolute;
    top: 17px;
    right: 10px;
    cursor: pointer;
  }
}
</style>