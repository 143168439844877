<template>
  <div class="book-payment-form__item">
    <div
      class="student__line"
      :style="{ color: group ? group.color : null }"
    ></div>
    <div class="student__link element">
      <div class="student-books">
        <div
          v-for="b in order.books"
          :key="b.id"
          class="book-payment-form__book"
        >
          <div class="book-payment-form__book__data">
            <vs-checkbox
              v-model="selected"
              :vs-value="b.link_id"
              @change="handleInput(b.link_id)"
              :disabled="b.free || b.give_out"
              :color="b.free || b.give_out ? 'danger' : 'primary'"
            >
              <vs-icon icon="import_contacts" size="small"></vs-icon>
              {{ b.name }}
            </vs-checkbox>

            <vs-chip class="book-payment-form__book__price"
              >{{ b.price }}р.</vs-chip
            >
            <vs-chip class="book-type" v-if="b.book_in_out_type.id">{{
              b.book_in_out_type.name
            }}</vs-chip>
          </div>
          <div class="book-payment-form__book__alert">
            <vs-alert
              color="danger"
              icon="warning_amber"
              v-if="b.free || b.give_out"
              size="small"
            >
              <span>Sent to give out</span>
            </vs-alert>
          </div>
        </div>
      </div>
    </div>
    <div class="order element">
      <span v-if="orderValue" class="discount">{{ orderValue }} р.</span>
    </div>
    <div class="date element">
      <input @keyup.enter="submit" v-model="date" placeholder="payment date" />
      <span class="paste-date-button">
        <vs-icon
          icon="content_paste"
          @click="pasteDate"
          title="Paste selected date"
        ></vs-icon>
      </span>
    </div>
    <div class="value element">
      <input @keyup.enter="submit" v-model="payValue" placeholder="value" />
    </div>
    <div class="source element">
      <input
        @keyup.enter="submit"
        v-model="source"
        placeholder="payment source"
        :list="sourceDatalistId"
      />
    </div>
    <div class="balance order element">
      <span
        class="credit border"
        :class="{
          negative: balance < 0,
          d15: orderDisount == 15,
          d25: orderDisount == 25,
          d50: orderDisount == 50,
        }"
        >{{ balance }} р.</span
      >
    </div>
    <div class="submit element">
      <button @click="submit">OK</button>
    </div>
    <div class="send element" v-if="order">
      <order-send-button
        v-if="student && student.order"
        :order="order"
        @setOrderAsSended="setOrderAsSended"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'bookPaymentFromItem',
  props: ['value', 'student', 'group', 'type', 'selectedDate', 'order'],
  data() {
    const { order } = this
    return {
      date: '',
      payValue: '',
      source: '',
      orderValue: order.id ? order.value : null,
      orderDisount: order.id ? order.discount : 0,
      payments: order ? order.payments : 0,
      //   balance_fact: this.student.balance.diff_fact,
      //   balance_plan: this.student.balance.diff_plan,
      sourceDatalistId: 'source_list',
      orderData: order,
      selected: this.value,
    }
  },
  computed: {
    balance: function() {
      if (this.orderValue)
        return this.$round(
          this.payments +
            parseFloat(this.payValue || 0) -
            parseFloat(this.orderValue || 0)
        )
      return this.$round(this.payValue)
    },
    ...mapState({
      bookTypes: state => state.books.bookTypes,
    }),
  },
  methods: {
    submit: function() {
      let { date, payValue, source } = this
      const pay_day = this.$moment(date, 'DD.MM.YYYY')
      const data = {
        student_id: this.student.id,
        value: payValue,
        source_synonym: source,
        pay_day: pay_day.unix(),
        schoolyear_id: this.order.schoolyear_id,
      }
      data.order_id = this.order.id
      data.order_type = 'OrdersStudentsBook'

      this.$store
        .dispatch('payments/setOrderPaymentAction', { payment: data })
        .then(data => {
          // TODO: clear form
          this.$vs.notify({
            title: `Payment saved`,
            text: `Payment with value ${data.saved[0].value}р. saved`,
            color: 'success',
            position: 'top-right',
          })
          this.date = ''
          this.payValue = ''
          this.source = ''
          this.payments += parseFloat(data.saved[0].value)
        })
        .catch(string => {
          this.$vs.notify({
            title: `error in save payment`,
            text: `${string}`,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    setOrderAsSended: function(order) {
      const { id, sended, payValue, payments } = order
      this.order = {
        id,
        sended,
        payments,
        value: payValue,
      }
    },
    pasteDate: function() {
      this.date = this.$moment(this.selectedDate).format('DD.MM.YYYY')
    },
    handleInput(v) {
      let output = [...this.value]
      if (!this.selected.includes(v)) {
        output.splice(output.indexOf(v), 1)
      } else {
        output.push(v)
      }
      this.$emit('input', output)
    },
  },
  watch: {
    value: function(newV) {
      this.selected = newV
    },
  },
}
</script>

<style lang="scss" scope>
.book-payment-form__item {
  background-color: #f1f1f1;
  margin: 3px 0;
  box-shadow: 0 0 2px 1px #ccc;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  input {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 7px;
    width: 100%;
  }
  .student__line {
    width: 4px;
    background-color: currentColor;
    position: absolute;
    height: 100%;
  }
  .order {
    width: 120px;
  }
  .element {
    padding: 7px;
    &.value,
    &.date,
    &.source {
      width: 10%;
    }
    &.date {
      position: relative;
      .paste-date-button {
        position: absolute;
        right: 10px;
        top: 9px;
        cursor: pointer;
        opacity: 0.5;
        i {
          font-size: 1.2rem;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .credit {
    &.border {
      padding: 3px 7px;
      border: 2px solid transparent;
      border-radius: 5px;
      width: 100%;
      display: block;
      text-align: center;
    }
  }
  .student__link {
    padding: 10px 25px;
    width: 20%;
    // min-width: 250px;
  }
  .submit {
    button {
      background-color: rgba(var(--vs-primary), 1);
      color: #fff;
      padding: 7px 15px;
      border-radius: 7px;
      border: none;
      &:hover {
        box-shadow: 0 0 3px 2px #ccc;
      }
    }
  }
  .balance {
    &.student {
      text-align: right;
      .balance__label {
        font-size: 0.7em;
        vertical-align: middle;
      }
    }
  }
  .student-books {
    display: flex;
    font-size: 0.8rem;
    flex-flow: row wrap;
  }
}

.book-payment-form {
  &__book {
    &__data {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }
    &__price {
      margin-left: 5px;
      padding: 5px;
      border-radius: 5px;
      background-color: #cfcfcf;
    }

    &__alert {
      .vs-alert {
        i {
          font-size: 1.2rem;
        }
      }
    }
  }
}
</style>
