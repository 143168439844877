<template>
  <div>
    <div
      v-for="group in data.groups"
      :key="group.id"
      class="payments__group-wrapper"
    >
      <div :class="{ hidden: group.hidden }">
        <router-link :to="{ name: 'Group', params: { group_id: group.id } }">
          <vs-avatar
            :icon="group.is_tutor ? 'portrait' : 'people'"
            :color="group.color"
          ></vs-avatar>
          <span>Group: {{ group.name }}</span>
        </router-link>
        <div v-for="s in group.students" :key="s.id">
          <FormItem :student="s" :group="group" :class="{ hidden: s.hidden }" />
        </div>
      </div>
    </div>
    <h2>Tutor</h2>
    <div
      v-for="tutor in data.tutor"
      :key="tutor.id"
      class="payments__tutor-wrapper"
    >
      <FormItem
        :student="tutor"
        type="tutor"
        :class="{ hidden: tutor.hidden }"
      />
    </div>
    <datalist id="source_list">
      <option
        :value="source"
        v-for="(source, index) in customSources"
        :key="index"
      ></option>
    </datalist>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FormItem from '@/components/payments/commonForm/formItem'

export default {
  props: ['search', 'data'],
  computed: {
    ...mapState({
      sources: state => state.payments.sources,
    }),
    customSources: function() {
      return this.sources.map(s => s.source_synonyms.split(',')[0])
    },
  },
  components: {
    FormItem,
  },
}
</script>

<style lang="scss">
.payments__group-wrapper {
  margin-bottom: 15px;
  .hidden {
    display: none;
  }
}
</style>
