<template>
  <div>
    <vs-upload @change="changeHandle" />
  </div>
</template>

<script>
export default {
  props: ["type", "id"],
  methods: {
    successUpload: function () {
      console.log("upload");
    },
    changeHandle: function (event, files) {
      this.$emit("startUpload");

      var formData = new FormData();
      formData.append("type", this.type ? this.type : "default");
      if (this.id) {
        formData.append("id", this.id);
      }

      formData.append("file", files[0], files[0].name);
      this.$store
        .dispatch("files/uploadFile", { data: formData })
        .then((data) => {
          this.$emit("successUpload", data);
        });
    },
  },
};
</script>

<style>
</style>