<template>
  <div class="parse__wrapper" v-if="item">
    <div class="parsed__index">{{ index }}</div>
    <div class="parsed__text">{{ text }}</div>
    <div class="parsed__value">{{ value }} р.</div>
    <div class="parsed__pay-day">{{ pay_day }}</div>
    <div class="parsed__group">
      <div class="select-input">
        <vs-icon icon="expand_more" class="select-arrow"></vs-icon>
        <select name="group" id="group" v-model="group_id">
          <option :value="g.id" v-for="g in groups" :key="g.id">
            {{ g.name }}
          </option>
          <option value="tutor" key="tutor">Tutor</option>
        </select>
      </div>
      <div class="error-message" v-if="!group_id">Group not defined</div>
    </div>
    <div class="parsed__student">
      <div class="select-input">
        <vs-icon icon="expand_more" class="select-arrow"></vs-icon>
        <select name="student" id="student" v-model="student_id">
          <option :value="s.id" v-for="s in students" :key="s.id">
            {{ s.name }}
          </option>
        </select>
        <div class="error-message" v-if="!student_id">Student not defined</div>
        <div class="warn-message" v-if="student && student.warn">
          Check student
        </div>
      </div>
    </div>
    <div class="parsed__order">
      <div v-if="order && order.value">Order: {{ order.value }} р.</div>
      <div v-else>No order</div>
    </div>
    <div class="source">
      <vs-chip v-if="source" color="#f1efc8">
        {{ source.source_name }}
      </vs-chip>
      <vs-chip v-else color="#f1efc8"> - </vs-chip>
    </div>
    <div class="delete">
      <vs-button
        icon="delete"
        type="flat"
        color="dark"
        title="Delete from list"
        @mouseup="$emit('deleteItem', index)"
      ></vs-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import getOrdersStudents from "@/api/orders/getOrdersStudents.js";
import getOrdersTutorByStudent from "@/api/orders/getOrdersTutorByStudent.js";
export default {
  props: ["item"],
  computed: {
    ...mapState({
      groups: (state) => state.groups.groups,
      studentsList: (state) => state.students.students,
      currentYear: (state) => state.years.currentSchoolYear,
    }),
    students: function () {
      if (this.group_id) {
        const group = this.groups.find((g) => g.id == this.group_id);
        if (group) {
          return group.students;
        } else {
          return this.studentsList;
        }
      }
      return [];
    },
  },
  data() {
    if (this.item) {
      return {
        index: this.item.index,
        group_id: this.item.group_id,
        student_id: this.item.student_id,
        source_id: this.item.source.id,
        color: this.item.group ? this.item.group.color : null,
        value: this.item.value,
        pay_day: this.item.pay_day
          ? this.$moment(this.item.pay_day * 1000).format("DD.MM.YYYY")
          : "-",
        text: this.item.text,
        order: this.item.order,
        student: this.item.student || {},
        source: this.item.source,
      };
    } else {
      return {
        group_id: undefined,
        color: undefined,
        student_id: undefined,
        source_id: undefined,
        value: undefined,
        pay_day: undefined,
        text: undefined,
        order: undefined,
        student: undefined,
        source: undefined,
      };
    }
  },

  methods: {
    checkOrder: async function () {
      const { group_id, student_id } = this;
      if (group_id == "tutor" && this.student_id) {
        let promise = new Promise((resolve) => {
          getOrdersTutorByStudent
            .call(this, {
              student: student_id,
              schoolyear_id: this.currentYear.id,
            })
            .then((response) => {
              const orders = response.data;
              if (orders.length) {
                resolve(orders[0]);
              } else {
                resolve([]);
              }
            });
        });
        return await promise;
      }
      if (this.group_id && this.student_id && this.pay_day) {
        let date = new Date(this.item.pay_day * 1000);
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        let promise = new Promise((resolve) => {
          // let promise = new Promise((resolve, reject) => {
          getOrdersStudents
            .call(this, {
              group_id,
              student_id,
              month,
              year,
            })
            .then((response) => {
              const orders = response.data;
              if (orders.length) {
                resolve(orders[0]);
              } else {
                resolve([]);
              }
            });
        });
        return await promise;
      }
    },
  },
  watch: {
    group_id: function (newV) {
      // console.log(newV);
      this.$emit("updateItem", {
        index: this.item.index,
        group_id: newV,
        color: this.item.group ? this.item.group.color : null,
        student_id: null,
        source_id: this.item.source ? this.item.source.id : undefined,
        value: this.item.value,
        pay_day: this.item.pay_day,
        payer: this.item.payer,
        source: this.item.source,
      });
    },
    student_id: async function (newV) {
      const data = {
        index: this.item.index,
        color: this.item.group ? this.item.group.color : null,
        student_id: newV,
        source_id: this.item.source ? this.item.source.id : undefined,
        value: this.item.value,
        pay_day: this.item.pay_day,
        payer: this.item.payer,
        source: this.item.source,
      };
      let order = await this.checkOrder();
      if (order) {
        this.order = order;
        data.order = order;
      }
      if (this.group_id == "tutor" && order.id) {
        data.order_id = this.order.id;
        data.order_type = "OrdersStudentsLesson";
        data.group_id = "tutor";
        data.group = null;
      } else {
        data.group_id = this.group_id ? this.group_id : this.item.group.id;
      }
      this.$emit("updateItem", data);
    },
  },
};
</script>

<style lang="scss">
.parse__wrapper {
  display: flex;
  flex-flow: row nowrap;
  gap: 15px;
  margin: 2px 0;
  background-color: #eee;
  padding: 10px;
  font-size: 0.85rem;
  align-items: center;
  .parsed__index {
    width: 2rem;
    text-align: center;
  }
  .parsed__text {
    // width: 20%;
    flex: 1;
  }
  .parsed__value {
    width: 7%;
    font-weight: 600;
    text-align: right;
    font-size: 1rem;
  }
  .parsed__pay-day {
    width: 7%;
  }
  .parsed__group {
    width: 10%;
  }
  .parsed__student {
    width: 15%;
  }
  .parsed__order {
    width: 10%;
  }
  .select-input {
    position: relative;
    select {
      display: block;
      background: none;
      border: 1px solid #ccc;
      background-color: #fff;
      padding: 10px;
      width: 100%;
      border-radius: 5px;
      -webkit-appearance: none;
      appearance: none;
      z-index: 1;
      cursor: pointer;
    }
    .select-arrow {
      position: absolute;
      right: 10px;
      top: 7px;
    }
  }
  .error-message,
  .warn-message {
    font-size: 0.8em;
    font-weight: 600;
  }
  .error-message {
    color: red;
  }
  .warn-message {
    color: rgb(255, 174, 0);
  }
  .delete {
    position: sticky;
    right: 0;
    width: 5%;
  }
}
</style>