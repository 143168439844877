<template>
  <div class="books-payment-form">
    <div class="header">
      <h2>Books</h2>
      <div class="year" v-if="currentSchoolYear.id">
        <vs-select v-model="currentSchoolYear.id" label="School Year">
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.name"
            v-for="(item, index) in years"
          />
        </vs-select>
      </div>
    </div>

    <header class="payments__header">
      <div class="payments__search">
        <SearchForm @searchEvent="searchHandler" />
      </div>
      <div class="payments__date"><DateForm @changeDate="changeDate" /></div>
      <div class="payment__book-form-add-button">
        <vs-button color="primary" @click="popupActive = true"
          >+ Add book
        </vs-button>
      </div>
    </header>
    <vs-progress :indeterminate="saving" color="primary"></vs-progress>
    <div class="data">
      <book-form
        :data="filteredData"
        :selectedDate="selectedDate"
        @updateOrders="getOrUpdateOrders"
      />
    </div>
    <vs-popup
      class="form-popup book-out__popup"
      classContent="book_amount__popup-content"
      title="Add Book"
      :active.sync="popupActive"
      v-if="popupActive"
    >
      <add-book-to-item-form @bookSaved="bookSavedHandler" />
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import addBookToItemForm from '@/components/books/addBookToItemForm.vue'
import SearchForm from '@/components/payments/commonForm/search'
import DateForm from '@/components/payments/commonForm/date'
import BookForm from './bookForm.vue'

export default {
  name: 'BookPaymentForm',
  data() {
    return {
      search: '',
      saving: false,
      showDebtor: false,
      showOverpay: false,
      minCredit: 50,
      minOverPay: 50,
      selectedDate: new Date(),
      popupActive: false,
    }
  },
  components: { addBookToItemForm, SearchForm, DateForm, BookForm },
  computed: {
    ...mapState({
      years: state => state.years.years,
      currentSchoolYear: state => state.years.currentSchoolYear,
      groups: state => state.groups.groups,
      ordersBooks: state => state.orders.ordersBooksAll,
    }),
    filteredData: function() {
      let groups = this.ordersBooks.reduce((list, group) => {
        group = { ...group }
        let students = group.students.reduce((list, student) => {
          if (!student.name.toLowerCase().includes(this.search.toLowerCase())) {
            return list
          }
          list.push(student)
          return list
        }, [])
        group.students = students
        list.push(group)
        if (!students.length) {
          group.hidden = true
          return list
        }
        return list
      }, [])
      return groups
    },
  },
  methods: {
    bookSavedHandler: function() {
      this.$vs.notify({
        title: 'Book saved',
        text: ``,
        color: 'success',
        position: 'top-right',
      })
      this.getOrUpdateOrders()
      this.popupActive = false
    },
    searchHandler: function(data) {
      this.search = data
    },
    changeDate: function(date) {
      this.selectedDate = date
    },
    getOrUpdateOrders: async function() {
      this.saving = true
      const schoolyear_id = this.currentSchoolYear.id
      await this.$store
        .dispatch('orders/getAllBookOrdersAction', {
          schoolyear_id,
        })
        .then(() => (this.saving = false))
    },
  },
  created: async function() {
    this.saving = true
    // --Years
    if (!this.years.length) {
      await this.$store.dispatch('years/getYearsAction')
    }
    // --Sources
    await this.$store.dispatch('payments/getSourcesAction')

    // --Groups
    const schoolyear_id = this.currentSchoolYear.id
    if (!this.groups.length) {
      const data = {
        schoolyear_id,
      }
      await this.$store.dispatch('groups/getGroupsAction', { data })
    }
    // --Orders
    this.getOrUpdateOrders()
  },
}
</script>

<style lang="scss" scoped>
.field {
  flex: 1;
}
.books-payment-form {
  .header {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin-bottom: 20px;
    h2 {
      margin-right: 40px;
      margin-top: 15px;
    }
    .book-form {
      margin-top: 1rem;
      margin-left: 1rem;
    }
  }
  .payments__group-wrapper {
    margin-top: 15px;
  }
}
</style>
