import Vue from 'vue'

export default function giveOutBookByLinks({ group_id, book_link_ids }) {
    const proxy = process.env.VUE_APP_PROXY
    const api_uri = process.env.VUE_APP_API_URI

    let url = proxy + api_uri + "/bookouts/batch"
    return Vue.http
        .post(url, { group_id, book_link_ids }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json()).catch((e) => { throw e })
}