<template>
  <div class="stat__wrapper">
    <table class="stat__table">
      <thead>
        <th
          data-sort="pay_day"
          data-sorted="true"
          @click="theadClickHandler"
          :class="{
            active: sortKey.includes('pay_day'),
            desc: sortKey.slice(0, 1) == '-',
          }"
        >
          date
        </th>
        <th>group / book / lesson</th>
        <th>student</th>
        <th class="stat__column__value">value</th>
        <th>source</th>
        <th
          data-sort="created"
          data-sorted="true"
          @click="theadClickHandler"
          :class="{
            active: sortKey.includes('created'),
            desc: sortKey.slice(0, 1) == '-',
          }"
        >
          id
        </th>
      </thead>
      <tbody>
        <tr :key="indextr" v-for="(tr, indextr) in data">
          <td>
            {{ $moment(tr.pay_day * 1000).format('DD.MM.YYYY') }}
          </td>

          <td>
            <div v-if="tr.type == 'groups'">
              <div
                class="stat-item"
                v-for="(i, index) in tr.items"
                :key="index"
                @click="$emit('updateFilters', 'group', [i.id])"
              >
                <vs-avatar icon="people" :color="i.color"></vs-avatar>
                <span>{{ i.name }}</span>
              </div>
            </div>
            <div v-else-if="tr.type == 'books'">
              <div
                class="stat-item"
                v-for="(i, index) in tr.items"
                :key="index"
                @click="$emit('updateFilters', 'book', [i.id])"
              >
                <vs-avatar icon="import_contacts"></vs-avatar>
                <span>{{ i.name }}</span>
              </div>
            </div>
            <div v-else-if="tr.type == 'lessons'">
              <div
                class="stat-item"
                v-if="tr.items[0].name && tr.items[0].color"
              >
                <vs-avatar
                  icon="portrait"
                  :color="tr.items[0].color"
                ></vs-avatar>
                <span>{{ tr.items[0].name }}</span>
              </div>
              <div class="stat-item" v-else>
                <vs-avatar icon="explore" icon-size="small"></vs-avatar>
                <span>Tutoring</span>
              </div>
            </div>
          </td>

          <td>
            <div
              class="stat-item"
              @click="$emit('updateFilters', 'student', [tr.student_id])"
            >
              <vs-avatar icon="person"></vs-avatar>
              <span>{{ tr.student_name }}</span>
            </div>
          </td>

          <td class="stat__column__value">{{ tr.value }} р.</td>

          <td class="source">
            <vs-chip v-if="tr.source_name" color="#f1efc8">
              <span @click="$emit('updateFilters', 'source', [tr.source_id])">{{
                tr.source_name
              }}</span>
            </vs-chip>
            <vs-chip v-else color="#f1efc8"> - </vs-chip>
          </td>
          <td>
            {{ tr.id }}
            <span class="created">
              {{ $moment(tr.created * 1000).format('DD.MM.YYYY HH.mm') }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['data', 'value'],
  data() {
    return {
      sortKey: this.value,
    }
  },
  methods: {
    theadClickHandler: function(e) {
      const { sort } = e.target.dataset
      this.sortKey = sort == this.sortKey ? `-${sort}` : sort
      this.$emit('input', this.sortKey)
    },
  },
}
</script>

<style lang="scss">
.stat__data {
  position: relative;
}
.stat__table {
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    padding: 5px 15px;
    border-bottom: 1px solid #e5e5e5;
  }
  thead {
    th {
      text-transform: uppercase;
      color: var(--thead-text-color);
      font-weight: normal;
      text-align: left;
      &[data-sorted='true'] {
        cursor: pointer;
        &.active:after {
          font-family: 'Material Icons';
          content: '\e5cf';
          top: 0.1rem;
          position: relative;
          display: inline-block;
          transform: rotateX(180deg);
        }
      }
      &.active.desc:after {
        transform: rotateX(0deg);
      }
    }
  }
  .stat__column__value {
    background-color: #f9f8f8;
    text-align: center;
  }
  td {
    .created {
      display: block;
      font-size: 0.8rem;
      color: var(--thead-text-color);
    }
    .stat-item {
      & > span {
        display: inline-block;
        border-bottom: 1px dashed #777;
      }
      cursor: pointer;
    }
    &.source {
      cursor: pointer;
    }
  }
}
.stat__totals {
  margin: 20px 0;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 30px 2px #eee;
  border: 1px solid #ddd;
  background-color: #fff;
  display: flex;
  flex-flow: row nowrap;
  position: sticky;
  top: 55px;
  z-index: 1;
  align-items: center;
  // max-width: 60%;
  &__item {
    margin: 0 15px;
  }
  &__groups {
    display: flex;
    flex-flow: row nowrap;
  }
  &__delimeter {
    flex: 1;
  }
  .scroll-top {
    border-right: 1px solid #ddd;
    padding: 0 10px 0 0;
  }
}
</style>
