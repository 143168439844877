<template>
  <div>
    <vs-button
      v-if="!order.sended"
      type="flat"
      @click="sendHandle($event, order.id)"
      icon="mail"
      size="small"
      >Send</vs-button
    >
    <span v-else>
      <vs-icon
        icon="done"
        color="success"
        :title="`Message sended at: ${$moment(order.sended * 1000).format(
          'DD.MM.YY HH:mm'
        )}`"
        @click="sendHandle($event, order.id)"
      ></vs-icon>
    </span>
  </div>
</template>

<script>
import sendOrderStudents from "@/api/orders/sendOrderStudents.js";

export default {
  props: {
    order: Object,
    orderType: {
      type: String,
      default: "group",
    },
  },
  methods: {
    sendHandle: function (e, id) {
      e.preventDefault();
      e.stopPropagation();
      const data = {
        id,
        sended: true,
        order_type: this.orderType,
      };
      sendOrderStudents.call(this, { data }).then((data) => {
        const order = data.data;
        if (order) {
          this.$emit("setOrderAsSended", order);
          this.$vs.notify({
            title: "Order marked as sended",
            text: `Order #${order.id} marked as sended at ${this.$moment(
              order.sended * 1000
            ).format("DD.MM.YY HH:mm")} by ${order.user.nickname}`,
            color: "success",
            position: "top-right",
          });
        }
      });
    },
  },
};
</script>

<style>
</style>